import { Component } from '@angular/core';

@Component({
  selector: 'app-soft-note-fee',
  templateUrl: './soft-note-fee.component.html',
  styleUrls: ['./soft-note-fee.component.scss'],
  standalone: false
})
export class SoftNoteFeeComponent {

  constructor() { }

}
